import {ICONS} from "constants/icons";
import {Icon} from "components/Icon";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/navigation";


export const Header = ({ children }) => {
    const isRoot = 
        window.location.pathname === ROUTES.root.path;

    const scrollPage = () => {
        const element = document.querySelector('#page');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }  
    }

    return (
        <header className="header">
            <div className="header__content">
                {isRoot ? (
                    <button
                        onClick={scrollPage}
                        className="header__logo"
                    >
                        <Icon icon={ICONS.newLogo} className="header__logo__icon"/>
                    </button>
                ) : (
                    <Link 
                        to={`${ROUTES.root.path}#page`} 
                        className="header__logo"
                        rel="noopener noreferrer"
                    >
                        <Icon icon={ICONS.newLogo} className="header__logo__icon"/>
                    </Link>
                )}
                {children}
            </div>
        </header>
    )
}
