import {ICONS} from "constants/icons";

import {ReactComponent as logo} from "assets/icons/logo.svg";
import {ReactComponent as newLogo} from "assets/icons/new-logo.svg";
import {ReactComponent as africa} from "assets/icons/africa.svg";
import {ReactComponent as bulbOutline} from "assets/icons/bulb-outline.svg";
import {ReactComponent as cashOutline} from "assets/icons/cash-outline.svg";
import {ReactComponent as shieldCheckmarkOutline} from "assets/icons/shield-checkmark-outline.svg";
import {ReactComponent as selectArrow} from "assets/icons/select-arrow.svg";
import {ReactComponent as smallRings} from "assets/icons/small-rings.svg";
import {ReactComponent as largeRings} from "assets/icons/large-rings.svg";
import {ReactComponent as albumsOutline} from "assets/icons/albums-outline.svg";
import {ReactComponent as analytics} from "assets/icons/analytics.svg";
import {ReactComponent as barChartOutline} from "assets/icons/bar-chart-outline.svg";
import {ReactComponent as readerOutline} from "assets/icons/reader-outline.svg";
import {ReactComponent as spiral} from "assets/icons/spiral.svg";
import {ReactComponent as menu} from "assets/icons/menu.svg";
import {ReactComponent as close} from "assets/icons/close.svg";
import {ReactComponent as toastError} from "assets/icons/toast-error.svg";
import {ReactComponent as toastSuccess} from "assets/icons/toast-success.svg";

const icons = {
    [ICONS.logo]: logo,
    [ICONS.newLogo]: newLogo,
    [ICONS.africa]: africa,
    [ICONS.bulbOutline]: bulbOutline,
    [ICONS.cashOutline]: cashOutline,
    [ICONS.shieldCheckmarkOutline]: shieldCheckmarkOutline,
    [ICONS.selectArrow]: selectArrow,
    [ICONS.smallRings]: smallRings,
    [ICONS.largeRings]: largeRings,
    [ICONS.albumsOutline]: albumsOutline,
    [ICONS.analytics]: analytics,
    [ICONS.barChartOutline]: barChartOutline,
    [ICONS.readerOutline]: readerOutline,
    [ICONS.spiral]: spiral,
    [ICONS.menu]: menu,
    [ICONS.close]: close,
    [ICONS.toastError]: toastError,
    [ICONS.toastSuccess]: toastSuccess
};
export const Icon = ({ icon, className }) => {
    const Icon = icons[icon]

    return <Icon className={className}/>

}

