import {useContext, useState} from "react";

import {LocalizationContext} from "contexts/localizationContext";
import {ICONS} from "constants/icons";
import {Button} from "components/Button";
import {LanguageSelect} from "components/LanguageSelect";
import {HEADER_NAVIGATION, ROUTES} from "constants/navigation";
import {LOCALE_STRINGS} from "constants/locales";
import { Link } from "react-router-dom";
import { getLinkToPortal } from "helpers/getLinkToPortal";
import { Icon } from "components/Icon";
import { joinClassNames } from "helpers/joinClassNames";

export const MobileMenu = () => {
    const { language } = useContext(LocalizationContext);
    const linkToPortal = getLinkToPortal(window.location.hostname);
    const path = window.location.pathname;
    const isRoot = window.location.pathname === ROUTES.root.path;

    const [isOpen, setIsOpen] = useState(false);

    const onContentClick = (e) => {
        e.stopPropagation();
    };

    const scrollPage = () => {
        const element = document.querySelector('#page');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }  
    }

    return (
        <>
            <Button
                className="header__actions__menu"
                icon={ICONS.menu}
                onClick={() => setIsOpen(true)}
            />
            {isOpen && (
                <div className="mobileMenu__backdrop" onClick={() => setIsOpen(false)}>
                    <div className="mobileMenu__content" onClick={onContentClick}>
                        <div className="mobileMenu__header flex items-center gap-2-5">
                            <a href="/" className="header__logo mr-auto">
                                <Icon icon={ICONS.newLogo} className="header__logo__mobile__icon"/>
                            </a>
                            <Link
                                className="header__actions__login"
                                to={linkToPortal}>{LOCALE_STRINGS[language].login}
                            </Link>
                            <Button
                                className="mobileMenu__close"
                                icon={ICONS.close}
                                onClick={() => setIsOpen(false)}
                            />
                        </div>
                        <nav className="mobileMenu__nav mt-6">
                            {Object.values(HEADER_NAVIGATION).map(section => {
                                const isRootRoute = section.path === ROUTES.root.path && isRoot;

                                return (
                                    isRootRoute ? (
                                        <button
                                            className={joinClassNames(
                                                "mobileMenu__nav__item__link head-button",
                                                path === section.path && "active-path"
                                            )}
                                            onClick={() => {
                                                scrollPage();
                                                setIsOpen(false);
                                            }}
                                        >
                                                {LOCALE_STRINGS[language][section.title]}
                                        </button>
                                    ) : (
                                        <Link
                                            key={`mobileHeader${section.path}`}
                                            onClick={() => setIsOpen(false)}
                                            className={joinClassNames(
                                                "mobileMenu__nav__item__link",
                                                path === section.path && "mobileMenu__nav__item__link-active"
                                            )}
                                            to={`${section.path}#page`}>{LOCALE_STRINGS[language][section.title]}
                                        </Link>
                                    )
                                )
                            })}
                        </nav>
                        <LanguageSelect/>
                    </div>
                </div>
            )}
        </>
    );
};
