import { LOCALE_STRINGS } from "constants/locales"
import { QuestionCard } from "./QuestionCard"


export const QuestionsSection = ({
    language = 'EN'
}) => {
    const QUESTIONS = [
        {
            titleKey: 'question_1_title',
            title: LOCALE_STRINGS[language]['question_1_title'],
            content: LOCALE_STRINGS[language]['question_1_content']
        },{
            titleKey: 'question_2_title',
            title: LOCALE_STRINGS[language]['question_2_title'],
            content: LOCALE_STRINGS[language]['question_2_content']
        },{
            titleKey: 'question_3_title',
            title: LOCALE_STRINGS[language]['question_3_title'],
            content: LOCALE_STRINGS[language]['question_3_content']
        },{
            titleKey: 'question_4_title',
            title: LOCALE_STRINGS[language]['question_4_title'],
            content: LOCALE_STRINGS[language]['question_4_content']
        },{
            titleKey: 'question_5_title',
            title: LOCALE_STRINGS[language]['question_5_title'],
            content: LOCALE_STRINGS[language]['question_5_content']
        },{
            titleKey: 'question_6_title',
            title: LOCALE_STRINGS[language]['question_6_title'],
            content: LOCALE_STRINGS[language]['question_6_content']
        },
        {
            titleKey: 'question_7_title',
            title: LOCALE_STRINGS[language]['question_7_title'],
            content: LOCALE_STRINGS[language]['question_7_content']
        },
        {
            titleKey: 'question_8_title',
            title: LOCALE_STRINGS[language]['question_8_title'],
            content: (
                <span>
                    {LOCALE_STRINGS[language]['question_8_contentBefore']}
                    <a 
                        className="contact-link" 
                        href="/tbml#restricted"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {LOCALE_STRINGS[language]['question_8_contentLink']}
                    </a>
                    {LOCALE_STRINGS[language]['question_8_contentAfter']}
                </span>
            )
        }
    ]

    return (
        <section className="questions-section w-full">
            <div className="questions-card px-10 py-20 flex-center flex-col">
                <div className="flex-col gap-3 mb-12">
                    <h2 className='title'>
                        {LOCALE_STRINGS[language].questions_title}
                    </h2>
                    <div className="undertitle">
                        {LOCALE_STRINGS[language].questions_undertitle}
                    </div>
                </div>
                <div className='flex-col gap-5 items-center mb-5'>
                    {QUESTIONS.map(({ title, content, titleKey }) => (
                        <QuestionCard
                            key={titleKey}
                            title={title}
                            content={content}
                        />
                    ))}
                </div>
                <div className="questions-undertitle">
                    <div>
                        {LOCALE_STRINGS[language].questions_still}
                    </div>
                    <div>
                        {LOCALE_STRINGS[language].questions_contact_before}
                        <a href="mailto:info@ezerfinance.com" className="contact-link">info@ezerfinance.com</a>
                        {LOCALE_STRINGS[language].questions_contact_after}
                    </div>
                </div>
            </div>
        </section>
    )
}