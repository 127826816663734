import {Link} from "react-router-dom";
import {Header} from "components/Header";
import {LOCALE_STRINGS} from "constants/locales";
import {TermsOfUseAndPrivacyPolicyFooter} from "components/Footer/TermsOfUseAndPrivacyPolicyFooter";
import {ROUTES} from "constants/navigation";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";

export const PrivacyPolicy = () => {
    const language = useDynamicLanguage();

    return (
        <div className="termsOfUseAndPrivacyPolicy">
            <Header />
            <div id="page" />
            <main className="termsOfUseAndPrivacyPolicy__main">
                <h1 className="termsOfUseAndPrivacyPolicy__title">{LOCALE_STRINGS[language].privacyPolicy}</h1>
                <div className="termsOfUseAndPrivacyPolicy__main__content">
                    <p>
                        {LOCALE_STRINGS[language].privacyPolicyContent.atVantage360IncAccessibleFrom}
                        <span>{" "}</span>
                        <a className="termsOfUseAndPrivacyPolicy__email__link"
                            href="https://ezerfinance.com/">www.ezerfinance.com
                        </a>
                        <span>{" "}</span>
                        {LOCALE_STRINGS[language].privacyPolicyContent.oneOfOurMainPriorities}
                    </p>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.consent}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.consentContent.paragraphOne}
                        </p>
                        <ol className="termsOfUseAndPrivacyPolicy__section-list">
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.consentContent.paragraphTwo}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.consentContent.paragraphThree}
                            </li>
                        </ol>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.informationWeCollect}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.informationWeCollectContent.paragraphOne}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.informationWeCollectContent.paragraphTwo}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformation}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphOne}
                        </p>
                        <ol className="termsOfUseAndPrivacyPolicy__section-list">
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphTwo}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphThree}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphFour}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphFive}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphSix}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].privacyPolicyContent.howWeUseOurInformationContent.paragraphSeven}
                            </li>
                        </ol>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.logFiles}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.logFilesContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.cookies}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.cookiesContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRights}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraph}
                        </p>
                        <ol className="termsOfUseAndPrivacyPolicy__section-list">
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphOne}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphTwo}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphThree}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphFour}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphFive}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-paragraph">
                                {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphSix}
                            </li>
                        </ol>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.GDPRDataProtectionRightsContent.paragraphSeven}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.changesToThisPrivacyPolicy}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.changesToThisPrivacyPolicyContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].privacyPolicyContent.contactUs}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].privacyPolicyContent.contactUsContent.paragraphOne}
                            <span>{" "}</span>
                            <a className="termsOfUseAndPrivacyPolicy__email__link"
                                href="mailto:info@ezerfinance.com">info@ezerfinance.com.
                            </a>
                        </p>
                    </section>
                </div>
            </main>
            <TermsOfUseAndPrivacyPolicyFooter>
                <Link
                    to={ROUTES.termsOfUse.path} className="termsOfUseAndPrivacyPolicy__footer__links__item"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {LOCALE_STRINGS[language][ROUTES.termsOfUse.name]}
                </Link>
            </TermsOfUseAndPrivacyPolicyFooter>
        </div>
    );
};
