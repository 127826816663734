import { Icon } from "components/Icon"
import { ICONS } from "constants/icons"
import { LOCALE_STRINGS } from "constants/locales"
import { GetStartedButtonWithModal } from "pages/Home/components/GetStartedButtonWithModal"

export const GetStartedSection = ({
    language = 'EN'
}) => {
    return (
        <section className="home-page__getStarted">
            <div className="home-page__getStarted__content">
                <div className="home-page__getStarted__logo">
                    <Icon icon={ICONS.newLogo} className="home-page__getStarted__logo__icon"/>
                </div>
                <p className="home-page__getStarted__text">{LOCALE_STRINGS[language].homePage_getStarted_title}</p>
                <GetStartedButtonWithModal />
            </div>
            <div className="get-started" />
        </section>
    )
}