import React from "react";
import { useFormikContext } from "formik";
import { NumericFormat } from "react-number-format";

export const NumberInput = ({
  autoComplete = "off",
  placeholderSuffix = "",
  name,
  value,
  placeholder,
  maxLength,
  onBlur,
}) => {
  const { setFieldValue } = useFormikContext();

  const placeholderString = placeholderSuffix
    ? `${placeholder} ${placeholderSuffix}`
    : placeholder;

  const handleValueChange = (values) => {
    setFieldValue(name, values.floatValue || "");
  };

  return (
    <NumericFormat
      className="customInput"
      fixedDecimalScale
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
      name={name}
      placeholder={placeholderString}
      value={value}
      autoComplete={autoComplete}
      onValueChange={handleValueChange}
      maxLength={maxLength}
      onBlur={onBlur}
    />
  );
};
