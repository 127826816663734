import {EMAIL_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH} from "constants/validations";

export const preOnboardingQuestionnaireFields = [
    {
        name: "businessName",
        label: "preOnboardingQuestionnaire_businessName",
        placeholder: "preOnboardingQuestionnaire_businessName",
        type: "text",
        maxLength: TEXT_FIELD_MAX_LENGTH,
        required: true,
    },
    {
        name: "yearsInBusiness",
        label: "preOnboardingQuestionnaire_yearsInBusiness",
        placeholder: "preOnboardingQuestionnaire_yearsInBusiness",
        type: "text",
        maxLength: TEXT_FIELD_MAX_LENGTH,
        required: true,
    },
    {
        name: "annualSales",
        label: "preOnboardingQuestionnaire_annualSales",
        placeholder: "preOnboardingQuestionnaire_annualSales",
        labelSuffix: "(USD)",
        placeholderSuffix: "(USD)",
        type: "text",
        maxLength: TEXT_FIELD_MAX_LENGTH,
        required: true,
        isNumberInput: true
    },
    {
        name: "tradeCommodity",
        label: "preOnboardingQuestionnaire_tradeCommodity",
        placeholder: "preOnboardingQuestionnaire_tradeCommodity",
        type: "text",
        maxLength: TEXT_FIELD_MAX_LENGTH,
        required: true,
    },
    {
        name: "email",
        label: "preOnboardingQuestionnaire_email",
        placeholder: "preOnboardingQuestionnaire_email",
        type: "text",
        maxLength: EMAIL_MAX_LENGTH,
        required: true,
    }
];

export const COUNTRIES = [
    {value: "GH", label: "preOnboardingQuestionnaire_Ghana"},
    {value: "CI", label: "preOnboardingQuestionnaire_IvoryCoast"}
];
